.pitch {
	width: 100vw; 
	height: 100vh;
	background: url(img/wood_floor.jpg);
}

.pitch svg {
	width: 100%;
	height: 100%;
}

#frameDurationSelect{
	font-size: large; 
	color: white;
	border-radius: 5px;
	border: 1px solid white;
	padding-left: 5px;
}

#pitchesButtons > * {
	cursor: pointer;
	fill: #000000;
	font-weight: bold;
	transition: all .25s;
}

#pitchesButtons > *:hover:not(.active){
	fill: #FFFFFF;
}

#pitchesButtons > .active {
	fill: #3483eb;
}

.st0{
	fill:#ee6730;
} 